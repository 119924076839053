import axios from 'axios'
import router from '../router/index'
import {
    Message
} from 'element-ui'

axios.defaults.baseURL = 'https://apib.huojihang.com'
axios.defaults.headers.post['Content-Type'] = 'application/json';

// 添加请求拦截器
axios.interceptors.request.use(function(config) {
    
    // 在发送请求之前做些什么 共同参数
    if (localStorage.getItem('token')) {

        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }
    return config

}, function(error) {
    // 对请求错误做些什么
    return Promise.reject(error)
});

// 添加响应拦截器
axios.interceptors.response.use(response => {
    // if(response.data.errorCode == 305){

    //   Message.error(response.data.errorMsg)
    //   router.push({
    //     path:'/'
    //   })
    // }
    // 对响应数据做点什么
    return response
}, function(error) {
    var errorMsg = error.response.data.message;
    // Message.error(errorMsg)
    // 对响应错误做点什么
    return Promise.reject(error)
});