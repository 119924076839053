import Vue from "vue";
import VueRouter from "vue-router";
import {
    Message
} from 'element-ui'

Vue.use(VueRouter);

const routes = [{
    path: '',
    redirect: 'login'
}, {
    path: "/login",
    name: "login",
    component: () =>
        import("../login.vue"),
    meta: {
        title: "登录",
    },
},
{
    path: "/userxy",
    name: "userxy",
    component: () =>
        import("../pages/xieyi/userxy"),
    meta: {
        title: "用户信息服务协议",
    },
}, {
    path: "/index",
    name: "index",
    component: () =>
        import("../index.vue"),
    children: [{
        path: "rizhi",
        name: "rizhi",
        component: () =>
            import("../pages/system/rizhi.vue"),
        meta: {
            title: "操作日志",
            requireAuth: true,
        },
    }, {
        path: "manager",
        name: "manager",
        component: () =>
            import("../pages/system/manager.vue"),
        meta: {
            title: "管理员管理",
            requireAuth: true,
        }

    }, {
        path: "/manager_add",
        name: "manager_add",
        component: () =>
            import("../pages/system/manager_add.vue"),
        meta: {
            title: "管理员管理",
            requireAuth: true,
        },
    }, {
        path: "shifu_edit",
        name: "shifu_edit",
        component: () =>
            import("../pages/yonghu_guanli/shifu_edit.vue"),
        meta: {
            title: "师傅编辑",
            requireAuth: true,
        },
    }, {
        path: "qianbao_detail",
        name: "qianbao_detail",
        component: () =>
            import("../pages/qianbao/qianbao_detail.vue"),
        meta: {
            title: "结算信息",
        },
    }, {
        path: "jueSe_manage",
        name: "jueSe_manage",
        component: () =>
            import("../pages/system/jueSe_manage.vue"),
        meta: {
            title: "角色管理",
            requireAuth: true,
        },
    }, {
        path: "shifu_yj",
        name: "shifu_yj",
        component: () =>
            import("../pages/jieSuan/shifu_yj.vue"),
        meta: {
            title: "师傅佣金管理",
            requireAuth: true,
        },
    }, {
        path: "shanghu_js",
        name: "shanghu_js",
        component: () =>
            import("../pages/jieSuan/shanghu_js.vue"),
        meta: {
            title: "商户结算管理",
            requireAuth: true,
        },
    }, {
        path: "shanghu_js_detail",
        name: "shanghu_js_detail",
        component: () =>
            import("../pages/jieSuan/shanghu_js_detail.vue"),
        meta: {
            title: "商户结算管理详情",
            requireAuth: true,
        },
    }, {
        path: "yuejie_mx",
        name: "yuejie_mx",
        component: () =>
            import("../pages/jieSuan/yuejie_mx.vue"),
        meta: {
            title: "查看明细",
            requireAuth: true,
        },
    }, {
        path: "fanli_jl",
        name: "fanli_jl",
        component: () =>
            import("../pages/jieSuan/fanli_jl"),
        meta: {
            title: "返利记录",
            requireAuth: true,
        },
    }, {
        path: "yongjin_jl",
        name: "yongjin_jl",
        component: () =>
            import("../pages/jieSuan/yongjin_jl"),
        meta: {
            title: "佣金记录",
            requireAuth: true,
        },
    }, {
        path: "danliang_jl",
        name: "danliang_jl",
        component: () =>
            import("../pages/jieSuan/danliang_jl"),
        meta: {
            title: "单量奖励记录",
            requireAuth: true,
        },
    }, {
        path: "shifu_tx",
        name: "shifu_tx",
        component: () =>
            import("../pages/jieSuan/shifu_tx"),
        meta: {
            title: "师傅提现管理",
            requireAuth: true,
        },
    }, {
        path: "chongzhi",
        name: "chongzhi",
        component: () =>
            import("../pages/qianbao/chongzhi.vue"),
        meta: {
            title: "充值明细",
            requireAuth: true,
        },
    }, {
        path: "tixian",
        name: "tixian",
        component: () =>
            import("../pages/qianbao/tixian.vue"),
        meta: {
            title: "提现管理",
            requireAuth: true,
        },
    }, {
        path: "chongzhi_huodong",
        name: "chongzhi_huodong",
        component: () =>
            import("../pages/qianbao/chongzhi_huodong.vue"),
        meta: {
            title: "充值活动管理",
            requireAuth: true,
        },
    }, {
        path: "shanghu_gl",
        name: "shanghu_gl",
        component: () =>
            import("../pages/yonghu_guanli/shanghu_gl.vue"),
        meta: {
            title: "商户管理",
            requireAuth: true,
        },
    }, {
        path: "geren_gl",
        name: "geren_gl",
        component: () =>
            import("../pages/yonghu_guanli/geren_gl.vue"),
        meta: {
            title: "个人管理",
            requireAuth: true,
        },
    }, {
        path: "shifu_gl",
        name: "shifu_gl",
        component: () =>
            import("../pages/yonghu_guanli/shifu_gl.vue"),
        meta: {
            title: "师傅管理",
            requireAuth: true,
        },
    }, {
        path: "dingdan_gl",
        name: "dingdan_gl",
        component: () =>
            import("../pages/dingDan/dingdan_gl.vue"),
        meta: {
            title: "订单管理",
            requireAuth: true,
        },
    }, {
        path: "dingdan_info",
        name: "dingdan_info",
        component: () =>
            import("../pages/dingDan/dingdan_info.vue"),
        meta: {
            title: "订单信息",
            requireAuth: true,
        },
    }, {
        path: "dingdan_edit",
        name: "dingdan_edit",
        component: () =>
            import("../pages/dingDan/dingdan_edit.vue"),
        meta: {
            title: "订单编辑",
            requireAuth: true,
        },
    }, {
        path: "buTuiKuan",
        name: "buTuiKuan",
        component: () =>
            import("../pages/dingDan/buTuiKuan.vue"),
        meta: {
            title: "补退款管理",
            requireAuth: true,
        },
    }, {
        path: "butuik_info",
        name: "butuik_info",
        component: () =>
            import("../pages/dingDan/butuik_info.vue"),
        meta: {
            title: "补退款信息",
            requireAuth: true,
        },
    }, {
        path: "pingtai_jieru",
        name: "pingtai_jieru",
        component: () =>
            import("../pages/dingDan/pingtai_jieru.vue"),
        meta: {
            title: "平台介入管理",
            requireAuth: true,
        },
    }, {
        path: "jieru_info",
        name: "jieru_info",
        component: () =>
            import("../pages/dingDan/jieru_info.vue"),
        meta: {
            title: "平台介入信息",
            requireAuth: true,
        },
    }, {
        path: "geren",
        name: "geren",
        component: () =>
            import("../pages/geren_set/geren.vue"),
        meta: {
            title: "个人设置",
            requireAuth: true,
        },
    }, {
        path: "shanghu_info",
        name: "shanghu_info",
        component: () =>
            import("../pages/yonghu_guanli/shanghu_info.vue"),
        meta: {
            title: "商户信息",
            requireAuth: true,
        },
    }, {
        path: "shifu_jn_add",
        name: "shifu_jn_add",
        component: () =>
            import("../pages/yonghu_guanli/shifu_jn_add.vue"),
        meta: {
            title: "添加师傅技能",
            requireAuth: true,
        },
    }, {
        path: "shifu_info",
        name: "shifu_info",
        component: () =>
            import("../pages/yonghu_guanli/shifu_info.vue"),
        meta: {
            title: "师傅信息",
            requireAuth: true,
        },
    }, {
        path: "jueSe_add",
        name: "jueSe_add",
        component: () =>
            import("../pages/system/jueSe_add"),
        meta: {
            title: "新建角色",
            requireAuth: true,
        },
    }, {
        path: "quanxian",
        name: "quanxian",
        component: () =>
            import("../pages/system/quanxian"),
        meta: {
            title: "权限管理",
            requireAuth: true,
        },
    }, {
        path: "fenlei",
        name: "fenlei",
        component: () =>
            import("../pages/xinxi_guanli/fenlei"),
        meta: {
            title: "分类管理",
            requireAuth: true,
        },
    }, {
        path: "jiaju",
        name: "jiaju",
        component: () =>
            import("../pages/xinxi_guanli/jiaju"),
        meta: {
            title: "家具管理",
            requireAuth: true,
        },
    }, {
        path: "yswenzi_gl",
        name: "yswenzi_gl",
        component: () =>
            import("../pages/xinxi_guanli/yswenzi_gl"),
        meta: {
            title: "预设文字管理",
            requireAuth: true,
        },
    }, {
        path: "gonggao_fl",
        name: "gonggao_fl",
        component: () =>
            import("../pages/xinxi_guanli/gonggao_fl"),
        meta: {
            title: "公告分类",
            requireAuth: true,
        },
    }, {
        path: "gonggao_gl",
        name: "gonggao_gl",
        component: () =>
            import("../pages/xinxi_guanli/gonggao_gl"),
        meta: {
            title: "公告管理",
            requireAuth: true,
        },
    },
    {
        path: "jiaju_info",
        name: "jiaju_info",
        component: () =>
            import("../pages/xinxi_guanli/jiaju_info"),
        meta: {
            title: "家具信息",
            requireAuth: true,
        },
    },
    {
        path: "status_guanli",
        name: "status_guanli",
        component: () =>
            import("../pages/status_guanli/index"),
        meta: {
            title: "状态管理",
            requireAuth: true,
        },
    },
    {
        path: "gonggao",
        name: "gonggao",
        component: () =>
            import("../pages/gonggao_guanli/gonggao"),
        meta: {
            title: "公告管理",
            requireAuth: true,
        },
    },
    {
        path: "gonggaoDetail",
        name: "gonggaoDetail",
        component: () =>
            import("../pages/gonggao_guanli/gonggaoDetail"),
        meta: {
            title: "公告详情",
            requireAuth: true,
        },
    },
    {
        path: "richText",
        name: "richText",
        component: () =>
            import("../pages/gonggao_guanli/richText"),
        meta: {
            title: "公告管理",
            requireAuth: true,
        },
    },
    {
        path: "banner",
        name: "banner",
        component: () =>
            import("../pages/gonggao_guanli/banner"),
        meta: {
            title: "公告专区管理",
            requireAuth: true,
        },
    },
    {
        path: "peixun_gl",
        name: "peixun_gl",
        component: () =>
            import("../pages/peixiun_guanli/peixun_gl"),
        meta: {
            title: "线下培训管理",
            requireAuth: true,
        },
    },
    {
        path: "peixun_sh",
        name: "peixun_sh",
        component: () =>
            import("../pages/peixiun_guanli/peixun_sh"),
        meta: {
            title: "线下培训审核",
            requireAuth: true,
        },
    },
    {
        path: "jinneg_sh",
        name: "jinneg_sh",
        component: () =>
            import("../pages/peixiun_guanli/jinneg_sh"),
        meta: {
            title: "技能审核",
            requireAuth: true,
        },
    },
    {
        path: "xianxia_peixun",
        name: "xianxia_peixun",
        component: () =>
            import("../pages/peixiun_guanli/xianxia_peixun"),
        meta: {
            title: "线下培训计划管理",
            requireAuth: true,
        },
    },
    {
        path: "jifen_guanli",
        name: "jifen_guanli",
        component: () =>
            import("../pages/jifen_shangcheng/jifen_guanli"),
        meta: {
            title: "积分管理",
            requireAuth: true,
        },
    },
    {
        path: "shangcheng_guanli",
        name: "shangcheng_guanli",
        component: () =>
            import("../pages/jifen_shangcheng/shangcheng_guanli"),
        meta: {
            title: "商城管理",
            requireAuth: true,
        },
    },
    {
        path: "shangcheng_order",
        name: "shangcheng_order",
        component: () =>
            import("../pages/jifen_shangcheng/shangcheng_order"),
        meta: {
            title: "商城订单管理",
            requireAuth: true,
        },
    },
    {
        path: "jl_pz",
        name: "jl_pz",
        component: () =>
            import("../pages/jili_peizhi/jl_pz"),
        meta: {
            title: "激励规则配置",
            requireAuth: true,
        },
    },
    {
        path: "sf_pm",
        name: "sf_pm",
        component: () =>
            import("../pages/shifu_pm/sf_pm"),
        meta: {
            title: "金牌师傅配置",
            requireAuth: true,
        },
    }, {
        path: "chatIm",
        name: "chatIm",
        component: () =>
            import("../IM/chatIm"),
        meta: {
            title: "在线沟通",
            requireAuth: true,
        },
    },

    ]
}


];

const router = new VueRouter({
    mode: "history",
    routes
});


// 导航守卫
// 参数1 : to 目标路由对象
// 参数2 : from 来源路由对象
// 参数3 : next() 下一步
router.beforeEach((to, from, next) => {

    //需要登录的页面
    if (to.meta.requireAuth) {
        let token = localStorage.getItem('token')
        if (token) {
            next()

        } else {
            next({
                path: '/'
            })
            return
        }
    } else {
        next()
    }


})
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
            // 处理其他错误
            console.error(err);
        }
    });
};

export default router;