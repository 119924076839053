import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

//element -ui使用
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//vue axios 使用
import axios from 'axios'
import './utils/axios'
Vue.prototype.$http = axios

import { TUIComponents, TUIChatKit, genTestUserSig } from "./TUIKit";
import { TUILogin } from "@tencentcloud/tui-core";

const SDKAppID = 1600006029// Your SDKAppID
const secretKey = "2bf23daa68a8adc18fb13d585838ed09f6cc971726eb88d28d7f47b281b0e556" //Your secretKey
// const userID =localStorage.getItem('userID')?localStorage.getItem('userID'):'TestOne11'; // User ID
const userID ='huojihang-9-40'; // User ID
 // 
TUIChatKit.components(TUIComponents, Vue);

TUIChatKit.init();

TUILogin.login({
    SDKAppID,
    userID,
    userSig: genTestUserSig({
        SDKAppID,
        secretKey,
        userID,
    }).userSig,
    useUploadPlugin: true,
    useProfanityFilterPlugin: false,
    framework: "vue2",
});


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')