<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'app',
        components: {

        },
        provide() {

            return {
                reload: this.reload
            }
        },
        data() {
            return {
                isRouterAlive: true
            }
        },
        methods: {
            // reload() {
            //     this.isRouterAlive = false
            //     this.$nextTick(() => this.isRouterAlive = true)
            // }
        },
    }
</script>

<style>
    html,
    body {
        height: 100%;
        margin: 0;
        padding: 0;
    }
</style>